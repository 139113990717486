@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;300;400;500&display=swap');
*{
  font-family: 'Barlow', sans-serif;
}
.hero {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('https://images.unsplash.com/photo-1597532516363-c4561058a3cf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80');
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 0 10%;
}

/* .hero::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
} */

.hero-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white!important;
}

.hero-description {
  font-size: 18px;
  color: white!important;
}

.hero-button{
  background-color: #F9A826;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border:none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.hero-button:hover{
  background-color: #F9A826;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border:none;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.8;
}